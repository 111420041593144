<template>
    <v-card class="cart-container" style="height: 100% !important">
        <v-card-text class="pt-1 pl-0 pr-0" style="height: 100% !important">
            <template>
                
                <v-row class="pl-3 pr-3">
                    <v-col cols="12" class="pt-3 pb-3">
                        <v-menu
                            v-model="menuDateInvoice"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    ref="order_date"
                                    :value="date_invoice"
                                    label="Fecha"
                                    readonly
                                    clearable
                                    v-on="on"
                                    outlined
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker :value="date_invoice" 
                                @input="menuDateInvoice = false" 
                                no-title
                                locale="es"
                                @change="set_date_invoice">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <hr class="separator-line" v-if="selected_station && worker == null">
                <v-row class="pl-3 pr-3" v-if="selected_station && worker == null">
                    <v-col cols="11" class="pb-4">
                        <v-autocomplete                             
                            :value="worker_id"
                            :items="workers"
                            label="Trabajador"
                            hide-details
                            outlined
                            :item-text="customTextWorkers"
                            item-value="id"
                            clearable
                            @change="handleSelectWorker"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="pl-3 pr-3" v-if="worker">
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-list two-line class="pt-0 pb-0">
                            <v-list-item class="pl-1 pr-1">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon color="blue" class="mr-2" size="18">
                                            fas fa-user-cog
                                        </v-icon>
                                        <span class="text-uppercase font-weight-bold cart-label-customer">{{ worker.fullname }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span class="ml-8">{{ worker.payment_type }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click="handleClearWorker" color="red">
                                        <v-icon>far fa-times-circle</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>                    
                </v-row>
                <hr class="separator-line">
                
                <v-row class="pl-3 pr-3">
                    <v-col cols="11" class="pb-4" v-if="customer == null">
                        <v-autocomplete                             
                            :value="customer_id"
                            :items="customers"
                            label="Cliente"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            @change="handleSelectCustomer"
                            :filter="customFilter"
                        >
                            <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.fullname }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="data.item.type_document_id">
                                        {{ data.item.type_document.name }}: {{ data.item.document }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="1" class="pl-0" v-if="customer == null">
                        <v-icon color="success" class="mt-2"
                            @click="handleViewCreateCustomer">
                            fas fa-plus-circle
                        </v-icon>
                    </v-col>
                </v-row>

                <v-row class="pl-3 pr-3" v-if="customer">
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-list two-line class="pt-0 pb-0">
                            <v-list-item class="pl-1 pr-1">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon color="green" class="mr-2" size="18">
                                            fas fa-user-tag
                                        </v-icon>
                                        <span class="text-uppercase font-weight-bold cart-label-customer">{{ customer.fullname }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span class="ml-8">{{ customer.type_document.name }}: {{ customer.document }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click="handleClearCustomer" color="red">
                                        <v-icon>far fa-times-circle</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>                    
                </v-row>

                <hr class="separator-line">
                <v-row style="height: 41% !important">
                    <v-col cols="12" class="pt-1 pb-1">
                        <template>
                            <div class="section-list-items-cart">
                                <v-list dense two-line class="list-cart-items pt-0 pb-0" style="height: 100% !important">
                                    <v-list-item v-for="product in cart" :key="product.id" 
                                        class="cart-item pl-2 pr-2"
                                        >
                                        <v-list-item-content class="pt-0 pb-0 cart-item-content" @click="hanldleClickItemCart(product)">
                                            <v-list-item-title>
                                                <v-icon v-if="product.worker_id" 
                                                    color="blue" 
                                                    class="mb-1" 
                                                    size="13">
                                                    fas fa-user-cog
                                                </v-icon>
                                                <span class="text-uppercase font-weight-bold">
                                                    {{ product.description }}
                                                </span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>                                                
                                                <span class="cart-item-quantity">
                                                    {{ product.quantity }}
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action class="mb-1 cart-item-action">
                                            <v-list-item-action-text>
                                                <span v-if="product.discount_value" class="font-weight-bold mr-2">
                                                    {{ getAmountProductDiscount(product) | currency('S/') }}
                                                </span>
                                                <span v-else class="font-weight-bold mr-2">{{ product.subtotal | currency('S/') }}</span>
                                                
                                                <span @click="handleIncreaseItem(product)" class="cart-item-delete mr-1">
                                                    <v-icon size="18" color="green">fas fa-plus</v-icon>
                                                </span>
                                                <span @click="handleDecreaseItem(product)" class="cart-item-delete mr-1">
                                                    <v-icon size="18" color="amber">fas fa-minus</v-icon>
                                                </span>
                                                <span @click="handleDeleteItem(product)" class="cart-item-delete">
                                                    <v-icon size="18" color="red">fas fa-times</v-icon>
                                                </span>
                                                <v-icon v-if="product.is_bonus"
                                                    size="18" 
                                                    class="ml-2"
                                                    color="green"
                                                    @click="handleChangeBonus(product)">
                                                    fas fa-gift
                                                </v-icon>
                                                <v-icon v-else 
                                                    size="18" 
                                                    class="ml-2" 
                                                    color="grey lighten-1"
                                                    @click="handleChangeBonus(product)">
                                                    fas fa-gift
                                                </v-icon>
                                            </v-list-item-action-text>
                                            <v-list-item-action-text v-if="product.discount_value && product.discount_value > 0">
                                                <span class="label-amount-original label-price-discounted">
                                                    {{ product.subtotal | currency('S/') }}
                                                </span>
                                            </v-list-item-action-text>
                                            
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </template>
                    </v-col>
                </v-row>
                <hr class="separator-line">

                <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-list class="pt-0">
                            <v-list-item class="amount-summary">                                
                                <v-list-item-content class="pt-1 pb-1">
                                    <v-list-item-title>
                                        <span class="font-weight-bold label-discount">DESCUENTO</span>
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action class="mt-1 mb-1">
                                    <span class="font-weight-bold label-discount">{{ discount | currency('S/') }}</span>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>

                            <v-list-item class="amount-summary">                                
                                <v-list-item-content class="pt-1 pb-1">
                                    <v-list-item-title>
                                        <span class="font-weight-bold">SUBTOTAL</span>
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action class="mt-1 mb-1">
                                    <span class="font-weight-bold">{{ subtotal | currency('S/') }}</span>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>

                            <v-list-item class="amount-summary">
                                <v-list-item-content class="pt-1 pb-1">
                                    <v-list-item-title>
                                        <span class="font-weight-bold">IGV</span>
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action class="mt-1 mb-1">
                                    <span class="font-weight-bold">{{ igv | currency('S/') }}</span>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>

                            <v-list-item class="amount-summary">
                                <v-list-item-content class="pt-1 pb-1">
                                    <v-list-item-title>
                                        <span class="font-weight-bold">TOTAL</span>
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action class="mt-1 mb-1">
                                    <span class="font-weight-bold">{{ total | currency('S/') }}</span>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>

                <v-row justify="center" v-if="!selected_station">
                    <v-col cols="8" class="pt-0">
                        <v-btn block dark color="green" height="45" @click="handleShowPayment">
                            PAGAR {{ total | currency('S/') }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row justify="center" v-else>
                    <v-col cols="4" class="pt-0">
                        <v-btn dark block color="blue" height="45" @click="handleSaveSale" :loading="processing">
                            GUARDAR
                        </v-btn>
                    </v-col>
                    <v-col cols="7" class="pt-0" v-if="sale_station">
                        <v-btn dark block color="green" height="45" @click="handleShowPayment">
                            PAGAR {{ total | currency('S/') }}
                        </v-btn>
                    </v-col>
                </v-row>

            </template>
        </v-card-text>

        <Payment ref="payment"></Payment>

        <Prices ref="Prices"></Prices>

        <create-update ref="createUpdate"></create-update>

        <v-snackbar v-model="cant_pay" top color="error">
            {{ message_validation }}
            <v-btn dark text @click="handleCloseMessageValidation">
                Cerrar
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

import ArrayTools from '../../helpers/ArrayTools';
import Payment from './Payment';
import Prices from './Prices';
import CreateUpdate from '../../components/customers/CreateUpdate';

export default {
    name: 'Cart',
    components: {
        Payment,
        Prices,
        CreateUpdate
    },
    data() {
        return {
            menuDateInvoice: false,
            cant_pay: false,
            message_validation: null,
            processing: false
        }
    },
    computed: {
        ...mapState('authentication', [
            'office_id',
        ]),
        ...mapState('customers', [
            'customers'
        ]),
        ...mapState('employees', [
            'workers'
        ]),
        ...mapState('sales', [
            'cart',
            'subtotal',
            'igv',
            'total',
            'customer',
            'customer_id',
            'product',
            'discount',
            'type_operation',
            'date_invoice',
            'sale_station',
            'worker',
            'worker_id',
            'rounding',
            'total_rounded',
            'total_gratuitas'
        ]),
        ...mapState('stations', [
            'selected_station'            
        ])
    },
    methods: {
        ...mapMutations('sales', [
            'set_date_invoice'
        ]),
        ...mapActions('customers', [
            'getCustomers'
        ]),
        ...mapActions('employees', [
            'getWorkers'
        ]),
        ...mapActions('sales', [
            'run_set_cart',
            'replace_product_cart',
            'delete_product_cart',
            'clearAmount',
            'select_customer',
            'clearData',
            'run_set_sale',
            'setViewPrices',
            'select_product',
            'setTypeOperation',
            'setDateInvoice',
            'selectWorker',
            'storeByStation',
            'updateByStation'
        ]),
        customText(item){
            let text = item.fullname
            
            return text
        },
        customTextWorkers (item) {
            let text = item.fullname
            
            return text
        },
        handleSelectCustomer(value){
            if (value == null || value == undefined) {
                //this.customer_id = null;
                this.select_customer(null);
            } else {
                let selected_customer = ArrayTools.getElementById(this.customers, value);
                this.select_customer(selected_customer);
            }
        },
        handleSelectWorker(value){
            if (value == null || value == undefined) {
                //this.customer_id = null;
                this.selectWorker(null);
            } else {
                let selected_worker = ArrayTools.getElementById(this.workers, value);
                this.selectWorker(selected_worker);
            }
        },
        handleClearCustomer(){
            //this.customer_id = null
            this.select_customer(null);
        },
        handleClearWorker(){
            this.selectWorker(null);
        },
        handleDeleteItem(product){
            this.delete_product_cart(product);
        },
        handleIncreaseItem (product) {
            let productToAdd = Object.assign({}, product);
            productToAdd.quantity = parseFloat(productToAdd.quantity) + 1;
            productToAdd.subtotal = parseFloat(productToAdd.quantity) * parseFloat(productToAdd.price);
            let discountValue = this.calculateDiscountValue(productToAdd);
            productToAdd.discount_value = discountValue;
            this.replace_product_cart(productToAdd);
        },
        handleDecreaseItem (product) {
            let productToAdd = Object.assign({}, product);
            productToAdd.quantity = parseFloat(productToAdd.quantity) - 1;
            if (productToAdd.quantity == 0) {
                this.delete_product_cart(product);
            } else {
                productToAdd.subtotal = parseFloat(productToAdd.quantity) * parseFloat(productToAdd.price);
                let discountValue = this.calculateDiscountValue(productToAdd);
                productToAdd.discount_value = discountValue;
                this.replace_product_cart(productToAdd);
            }            
        },
        handleCloseMessageValidation() {
            this.cant_pay = false;
            this.message_validation = null;
        },
        handleShowPayment(){
            if (this.customer == null) {
                this.cant_pay = true;
                this.message_validation = 'Debe seleccionar un cliente.';
            } else if (this.cart.length == 0) {
                this.cant_pay = true;
                this.message_validation = 'Debe seleccionar al menos un producto.';
            } else {
                if (this.type_operation === 'sale') {
                    this.$refs.payment.showForm('Registro de pago de venta');
                } else {
                    this.$refs.payment.showForm('Registro de pago de pedido');
                }                
            }
        },
        hanldleClickItemCart(product) {
            this.select_product(product);
            this.setViewPrices(true);
        },
        handleChangeTypeOperation (value) {
            this.setTypeOperation(value);
            this.clearData();
        },
        getAmountProductDiscount(product) {
            let amount = 0;
            let subtotal = Math.round(product.subtotal * 100) / 100;
            let discount = Math.round(product.discount_value * 100) / 100;
            amount = Math.round( (subtotal - discount) * 100) / 100;

            return amount;
        },
        handleChangeBonus (product) {
            let productItem = Object.assign({}, product);
            productItem.is_bonus = !productItem.is_bonus;
            this.replace_product_cart(productItem);
        },
        handleViewCreateCustomer () {
            this.$refs.createUpdate.showForm('Nuevo cliente', 'create');
        },
        async handleSaveSale () {
            this.processing = true;
            if (this.customer == null) {
                this.cant_pay = true;
                this.message_validation = 'Debe seleccionar un cliente.';
                this.processing = false;
            } else if (this.cart.length == 0) {
                this.cant_pay = true;
                this.message_validation = 'Debe seleccionar al menos un producto.';
                this.processing = false;
            } else {
                let sale = this.setEntitySale();
                if (this.sale_station == null) {
                    await this.storeByStation(sale);
                } else {
                    await this.updateByStation(sale);
                }
                this.processing = false;
            }
        },
        setEntitySale () {
            let sale = {
                office_id: this.office_id,
                id: this.sale_station,
                date_invoice: this.date_invoice,
                worker_id: this.worker_id,
                customer_id: this.customer_id,
                type_operation: this.type_operation,
                products: this.cart,
                discount: this.discount,
                subtotal: this.subtotal,
                igv: this.igv,
                total: this.total,
                rounding: this.rounding,
                total_rounded: this.total_rounded,
                total_gratuitas: this.total_gratuitas,
                station_id: this.selected_station.id
            }

            return sale;
        },
        customFilter(item, queryText, itemText) {
            let textName = '';
            if (item.name) {
                textName = item.fullname.toLowerCase();             
            }
            let textDocument = '';
            if (item.document) {
                textDocument = item.document.toLowerCase();             
            }
            let searchText = queryText.toLowerCase()


            return textName.indexOf(searchText) > -1 || textDocument.indexOf(searchText) > -1
        },
        calculateDiscountValue (product) {
            let discount_value = 0;
            if (product.discount_type) {
                discount_value = parseFloat(product.discount_amount);
                if (product.discount_type == 'Porcentaje') {
                    let percentage = parseFloat(product.discount_amount);
                    let discount = parseFloat(product.subtotal) * (percentage / 100);
                    discount_value = Math.round(discount * 100) / 100;
                }
            }

            return discount_value;
        },
    },
    created () {
        this.setTypeOperation('sale');
        if (!this.selected_station) {
            this.setDateInvoice(this.$moment().format('YYYY-MM-DD'));
        }
    },
    mounted() {
        this.getCustomers();
        let filters = {
            office_id: this.office_id
        }
        this.getWorkers(filters);
        if (!this.selected_station) {
            this.run_set_cart([]);
            this.clearData();
            this.run_set_sale(null);
        }
    }
}
</script>

<style scoped>
.label-discount{
    color: #F44336;
}

.amount-summary{
    min-height: 30px !important;
}

.list-cart-items{
    overflow-y: auto;
}

.label-amount-original {
    display: block !important;
}

.v-list-item__action-text{
    font-size: .85rem !important;
}

.label-price-discounted{
    text-decoration: line-through;
    text-decoration-color: #C62828;
}

.section-list-items-cart{
    height: 35vh;
    overflow-y: auto;
}

.cart-item-content {
    cursor: pointer;
}
</style>