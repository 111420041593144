<template>
  <div style="height: 90% !important">
    <template>
      <v-row v-if="!selected_station">
        <v-col cols="3" class="pt-1 pr-3">
          <span>asdadajsfdasjgd</span>
          <v-btn-toggle
            v-model="type_item"
            mandatory
            dense
            color="green darken-3"
            style="width: 100%"
          >
            <v-btn value="product" class="btn-show-mode" style="width: 50%">
              <v-icon
                v-if="type_item == 'product'"
                class="mr-1"
                color="green"
                size="16"
              >
                fas fa-check
              </v-icon>
              PRODUCTOS
            </v-btn>
            <v-btn value="service" class="btn-show-mode" style="width: 50%">
              <v-icon
                v-if="type_item == 'service'"
                class="mr-1"
                color="green"
                size="16"
              >
                fas fa-check
              </v-icon>
              SERVICIOS
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="1" class="pl-0">
          <v-icon
            v-if="type_item == 'service'"
            color="success"
            @click="handleViewCreateService"
          >
            fas fa-plus-circle
          </v-icon>
        </v-col>
        <v-col cols="8" class="pt-1 pr-3">
          <v-text-field
            v-model="search_product"
            label="Buscar por nombre, código de barras"
            autofocus
            hide-details
            prepend-inner-icon="mdi-magnify"
            solo
            @keydown="handleInputSearchProduct"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="5" class="pt-1 pr-3">
          <v-btn-toggle
            v-model="type_item"
            mandatory
            dense
            color="green darken-3"
            style="width: 100%"
          >
            <v-btn value="product" class="btn-show-mode" style="width: 50%">
              <v-icon
                v-if="type_item == 'product'"
                class="mr-1"
                color="green"
                size="16"
              >
                fas fa-check
              </v-icon>
              PRODUCTOS
            </v-btn>
            <v-btn value="service" class="btn-show-mode" style="width: 50%">
              <v-icon
                v-if="type_item == 'service'"
                class="mr-1"
                color="green"
                size="16"
              >
                fas fa-check
              </v-icon>
              SERVICIOS
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="1" class="pl-0">
          <v-icon
            v-if="type_item == 'service'"
            color="success"
            @click="handleViewCreateService"
          >
            fas fa-plus-circle
          </v-icon>
        </v-col>
        <v-col cols="12" class="pt-1 pr-3">
          <v-text-field
            v-model="search_product"
            label="Buscar por nombre, código de barras"
            autofocus
            hide-details
            prepend-inner-icon="mdi-magnify"
            solo
            @keydown="handleInputSearchProduct"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-3 pb-0">
          <v-card class="section-catalogue">
            <v-card-text class="pl-0 pr-0 pt-0 pb-0">
              <template>
                <v-list
                  v-if="type_item == 'product'"
                  two-line
                  dense
                  class="pt-0"
                >
                  <v-list-item
                    v-for="product in catalogue"
                    :key="product.id"
                    class="catalogue-item"
                    @click="handleSelectProduc(product)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-bold">{{
                          product.description
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-icon color="red" class="mr-3" size="12"
                          >fas fa-tag</v-icon
                        >
                        <span class="mr-3 font-italic font-weight-bold"
                          >Stock:</span
                        >
                        <span class="font-weight-bold" v-if="product.stock">
                          {{ product.stock }}
                        </span>
                        <span class="font-weight-bold" v-else>
                          0
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <span class="font-weight-bold">{{
                        product.price | currency("S/")
                      }}</span>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-list v-else two-line dense class="pt-0">
                  <v-list-item
                    v-for="service in services"
                    :key="service.id"
                    class="catalogue-item"
                    @click="handleSelectProduc(service)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-bold">{{ service.name }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <span class="font-weight-bold">{{
                        service.base_price | currency("S/")
                      }}</span>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <add-quantity ref="addQuantity"></add-quantity>
    <create-service ref="createService"></create-service>
  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex';

import AddQuantity from './AddQuantity';
import ArrayTools from '../../helpers/ArrayTools';
import {BarCodeSupport} from '@/plugins/BarCodeSupport';
import CreateService from '../services/CreateUpdate';

export default {
  name: 'Catalogue',
  components: {
    AddQuantity,
    CreateService
  },
  data() {
    return {
      category: null,
      search_product: null,
      option_accesories: true,
      option_hardware: false,
      class_modality: '',
      brands: [],
      types: [],
      capacities: [],
      colors: [],
      dialog_chip: false,
      dialog_portabilidad: false,
      unit_id: null,
      type_item: 'product'
    }
  },
  computed: {
    ...mapState('inventories', [
      'catalogue'
    ]),
    ...mapState('sales', [
      'modality',
      'product',
      'type_operation',
      'cart'
    ]),
    ...mapState('services', [
      'services'
    ]),
    ...mapState('stations', [
      'selected_station'
    ])
  },
  methods: {
    ...mapActions('saleoptions', [
      'getSalesOptions'
    ]),
    ...mapActions('inventories', [
      'run_set_catalogue',
      'getCatalogue',
      'getCatalogueToSale'
    ]),
    ...mapActions('sales', [
      'add_product_to_cart',
      'select_modality',
      'select_product',
      'replace_product_cart'
    ]),
    ...mapActions('services', [
      'getServices'
    ]),
    customText(item) {
      let text = item.name;

      return text;
    },
    handleListHardware() {
      this.category = "Hardware";//null;
      this.option_accesories = false;
      this.option_hardware = true;
      this.search_product = null;
      this.searchProductInCatalogue();
    },
    handleInputSearchProduct(KeyboardEvent) {
      if (KeyboardEvent.code == 'Enter') {
        if (this.type_item == 'product') {
          this.searchProductInCatalogue();
        } else if (this.type_item == 'service') {
          let filterService = {
            name: this.search_product
          }
          this.getServices(filterService);
        }
      }
    },
    handleChangeUnit(value) {
      if (value !== undefined && value !== null) {
        this.unit_id = value;
      } else {
        this.unit_id = null;
      }
      this.searchProductInCatalogue();
    },
    handleSelectProduc(product) {
      if (this.type_item == 'product') {
        this.select_product(product);
        if (this.product.stock) {
          if (parseFloat(this.product.stock) > 0) {
            this.$refs.addQuantity.show();
          }
        }
      } else if (this.type_item == 'service') {
        let serviceProduct = this.convertServiceAsProduct(product);
        this.select_product(serviceProduct);
        this.$refs.addQuantity.show();
      }
    },
    convertServiceAsProduct(service) {
      return {
        barcode: null,
        brand_name: null,
        category: null,
        category_id: null,
        category_name: null,
        description: service.name,
        inventory_id: null,
        price: service.base_price,
        product_id: service.id,
        sku: null,
        stock: null,
        type_item: 'service'
      }
    },
    searchProductInCatalogue() {
      this.run_set_catalogue([]);
      let filters = {
        category: this.category,
        description: this.search_product,
        unit_id: this.unit_id
      };
      this.getCatalogueToSale(filters);
    },
    selectBarcode(data) {
      this.search_product = data;
      this.searchProductInCatalogue();
    },
    handleViewCreateService() {
      this.$refs.createService.showForm('REGISTRAR SERVICIO', 'create');
    }
  },
  created() {
    //this.run_set_catalogue([]);
    this.handleListHardware();
    this.getServices();
    console.log(this.selected_station);
  },
  mounted() {
    BarCodeSupport.$on('codeScanned', data => {
      this.selectBarcode(data)
    });
  }
}
</script>

<style scoped>
.selected-option {
  color: #ffffff !important;
  background-color: #023145 !important;
}

.selected-option > .v-card__text > span {
  color: #ffffff !important;
  background-color: #023145 !important;
}

.section-catalogue {
  height: 83vh;
  overflow-y: auto;
}

.catalogue-item {
  text-transform: uppercase;
  border-bottom: 1px solid #e0e0e0;
  min-height: 50px !important;
}

.btn-product-category {
  display: inline-block !important;
  width: 100%;
}

.sale-option-label {
  font-size: .8rem !important;
  font-weight: 600;
}

.option-img {
  padding-left: 15%;
  padding-top: 12px;
}

.label-modality {
  padding-left: 15% !important;
}

.sale-option-img {
  background-color: #023145; /* #00908b;*/
  color: #ffffff;
  width: 83%;
  height: 40px;
  padding-top: 5px;
  border-radius: 4px;
  text-align: center;
}

.btn-modality {
  height: 80px !important;
  width: 114px !important;
  /*margin: auto;*/
}

.modality-select {
  background-color: #ffe330;
}

.frm-portabilidad {
  border: 1px solid;
}
</style>